/* Importing the Roboto font */


/* Global Styles */
body {
  font-family: 'Roboto', sans-serif;
}

/* Common styles for both header and navbar */
.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #333;
  color: white;
  padding: 1rem;
  width: 100%; /* Ensuring full width */
  box-sizing: border-box; /* Includedd padding and border in width calculations */
}

/* Adjusted the navbar to use full available width */
.navbar {
  background-color: #333; /* Matched header background color */
  padding: 0.5rem;
  margin: 0;
  flex: 1; /* Allowd navbar to take up available space */
  display: flex;
  justify-content: flex-end; /* Alignd items to the right */
}

/* Navbar links */
.navbar ul {
  display: flex;
  list-style-type: none;
}

.navbar ul li {
  margin: 0 1rem;
}

.navbar ul li a {
  color: white;
  text-decoration: none;
  padding: 0.75rem 1.5rem;
  white-space: nowrap; /* Prevent text from wrapping */
}

/* Hover effect for navbar links */
.navbar ul li a:hover {
  background-color: #444; /* Slightly lighter shade for hover effect */
  text-decoration: underline;
}

/* Responsive styles for Navbar */

/* For small screens (max-width: 600px) */
@media (max-width: 600px) {
  .navbar ul {
    flex-direction: column;
    gap: 1rem; /* Increased gap between list items */
    align-items: center;
  }

  .navbar ul li {
    margin: 0.5rem 0; /* Added vertical spacing between list items */
  }

  .navbar ul li a {
    padding: 0.75rem 1.5rem; /* Adjusted padding for better readability */
  }
}

/* For screens smaller than 650px */
@media (max-width: 650px) {
  .navbar {
    flex-direction: column;
    align-items: center; /* Centered items horizontally */
  }

  .navbar ul {
    flex-direction: column;
    gap: 0.5rem;
    width: 100%; /* Make ul span full width */
    align-items: center;
  }

  .navbar ul li a {
    padding: 0.5rem 1rem; /* Maintaind padding for readability */
  }
}

/* Header and Footer font adjustments for smaller screens */
@media (max-width: 480px) {
  .header h1 {
    font-size: 1.8rem;
  }

  .footer {
    font-size: 0.9rem;
  }
}

/* Card styling */
.card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
  overflow: hidden;
}

.card img {
  width: 100%;
  height: 180px; /* Maintain image size */
  object-fit: fill; /* Ensure the image covers the container */
  border-radius: 8px;
}

.card h2 {
  margin: 1rem 0;
  color: #333;
  font-size: 1.2rem;
}

.card p {
  color: #666;
  font-size: 1rem;
}

/* Card Hover Effect */
.card:hover {
  transform: scale(1.1); /* Slight zoom */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2); /* Darker shadow */
}

/* Two cards per row for large screens */
@media (min-width: 1200px) {
  .cards {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); /* More space for larger screens */
  }
}

/* Button inside card */
.card button {
  position: relative;
  overflow: hidden;
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.card button::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0;
  height: 0;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  transform: translate(-50%, -50%);
  transition: width 0.4s ease, height 0.4s ease;
}

.card button:active::after {
  width: 300%;
  height: 300%;
}


/* General container for the course grid */
.course-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr)); /* Responsive grid layout */
  gap: 1.5rem;
  padding: 2rem;
}

/* Individual course cards */
.course-card {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  text-align: center;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.course-card:hover {
  transform: scale(1.05); /* Slight zoom on hover */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

/* Course images inside the cards */
.course-image {
  width: 100%;
  height: 150px; /* Set a fixed height to make all cards uniform */
  object-fit: cover; /* Ensures the image covers the container, maintaining aspect ratio */
  border-radius: 8px;
}

/* Course content */
.course-content {
  padding: 1rem 0;
}

.course-content h3 {
  font-size: 1.25rem;
  margin: 0.5rem 0;
  color: #333;
}

.course-content p {
  font-size: 1rem;
  color: #666;
  margin-bottom: 1rem;
}

.course-content button {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.course-content button:hover {
  background-color: #0056b3;
}



@media (max-width: 768px) {
  form {
    padding: 1rem; /* Adjusted padding for smaller screens */
  }

  h2 {
    font-size: 1.5rem; /* Reduced font size for smaller screens */
  }
}

/* CEO Message Section */
.ceo-message {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #f9f9f9;
  padding: 2rem;
  margin-bottom: 4rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.ceo-image {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin-right: 2rem;
  object-fit: cover;
}

.ceo-text {
  flex: 1;
  text-align: left;
}

.ceo-text p {
  color: #333;
  font-size: 1.2rem;
  line-height: 1.8;
}

/* Contact Us Section */
.contact-section {
  background-color: #007bff;
  color: white;
  padding: 2rem 0;
  text-align: center;
}

.contact-section h3 {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.contact-section p {
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}

/* Footer Styles */
.footer {
  background-color: #333;
  color: white;
  padding: 2rem;
  text-align: center;
  position: relative;
  width: 100%;
  bottom: 0;
  left: 0;
}

.navbar-buttons {
  display: flex;
  flex-wrap: wrap; /* Allow buttons to wrap */
  justify-content: flex-end; /* Align buttons to the right */
}

/* Optional: To adjust button size or padding for smaller screens */
@media (max-width: 600px) {
  .navbar-buttons {
    flex-direction: column; /* Stack buttons vertically on very small screens */
    align-items: flex-start; /* Align buttons to the left */
  }

  .navbar-buttons button {
    width: 100%; /* Make buttons full width on small screens */
    margin-bottom: 10px; /* Add space between buttons */
  }
}

.footer {
  background-color: #333; /* Background color */
  color: white; /* Text color */
  padding: 20px 0; /* Padding for top and bottom */
}

.social-icons {
  display: flex;
  justify-content: center; /* Center the icons */
  gap: 20px; /* Space between icons */
}

.icon-button {
  color: white; /* Icon color */
  transition: color 0.3s; /* Smooth color transition */
}

.icon-button:hover {
  color: #61dafb; /* Color on hover */
}

/* About Us Section Styling */
.about-container {
  background-color: #f5f5f5;
  padding: 4rem 2rem;
  text-align: center;
}

.about-heading {
  font-size: 2.5rem;
  margin-bottom: 2rem;
  color: #2c3e50;
}

.vision-mission {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  margin-bottom: 4rem;
}

.vision-statement, .mission-statement {
  max-width: 800px;
  background-color: #ffffff;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: left;
}

.vision-statement h3, .mission-statement h3 {
  color: #007bff;
  font-size: 1.8rem;
  margin-bottom: 1rem;
}

.vision-statement p, .mission-statement p {
  color: #666;
  font-size: 1.1rem;
  line-height: 1.6;
}

/* Styles for the carousel slide */
.carousel-slide {
  position: relative;
  width: 100%;
  height: 100%;
}

/* The image in the carousel */
.carousel-image {
  width: 100%;
  height: 350px; /* Adjust this height as needed */
  object-fit: fill;
}

/* Overlay content container */
.carousel-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white; /* Text color for overlay */
  background: rgba(0, 0, 0, 0.4); /* Semi-transparent background overlay */
  text-align: center;
  padding: 20px;
}

/* Style the title */
.carousel-content h2 {
  font-size: 2rem;
  margin-bottom: 10px;
  z-index: 2;
  color: #ffffff;
}

/* Style the description */
.carousel-content p {
  font-size: 1.2rem;
  margin-bottom: 20px;
  z-index: 2;
}

/* Style the button */
.carousel-content button {
  padding: 10px 20px;
  font-size: 1rem;
  background-color: transparent; /* Make button transparent */
  color: white;
  border: 2px solid white; /* Add a border to make it visible */
  border-radius: 5px;
  cursor: pointer;
  z-index: 2;
  transition: background-color 0.3s ease; /* Smooth transition effect */
}

.carousel-content button:hover {
  background-color: rgba(230, 74, 25, 0.2); /* Slight shade on hover using rgba */
  border-color: rgba(255, 255, 255, 0.5); /* Optional: lighten border on hover */
}



@keyframes slide-in {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fade-in {
  to {
    opacity: 1;
  }
}

/* Media Query for Mobile Responsiveness */
@media (max-width: 600px) {
  form {
    padding: 1rem;
  }
  
  form h2 {
    font-size: 1rem;
  }

  form button {
    font-size: 0.9rem;
    padding: 0.6rem 1rem;
  }
}
