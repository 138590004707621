body {
    margin: 0;
    font-family: 'Roboto', sans-serif;
    background-color: #f0f0f0;
  }
  
  h1, h2, h3, h4, h5, h6 {
    color: #333;
  }
  
  .container {
    margin: 0 auto;
    max-width: 1200px;
  }
  
  .card {
    transition: transform 0.2s;
  }
  
  .card:hover {
    transform: scale(1.05);
  }
  